/* You can add global styles to this file, and also import other style files */
@import "~font-awesome/css/font-awesome.min.css";
@import "~bootstrap/dist/css/bootstrap.css";
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

body.publicPage {
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #0052D4, #65C7F7, #9CECFB);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #0052D4, #65C7F7, #9CECFB);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */  
    }
  
    :root {
      --input-padding-x: 1.5rem;
      --input-padding-y: .75rem;
    }
  
    
    .form-control.required {
      border: 1px solid red;
    }
  
    .required {
      color : red;
    }